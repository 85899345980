import { Suspense, lazy, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { store, persistor, PersistGate } from "@syntbeheer/api/store";
import { Provider } from "react-redux";
import * as usersSelectors from "@syntbeheer/api/services/users/selectors";
import { getDefinitions } from "@syntbeheer/api/services/definitions/index";
import Dashboard from "./components/pages/dashboard/Dashboard.js";
import { Font } from "@react-pdf/renderer";
import ReactGA from "react-ga";
import Poppins from "./Poppins.ttf";
import PoppinsBold from "./Poppins-Bold.ttf";

Font.register({
  family: "Poppins",
  fonts: [{ src: Poppins }, { src: PoppinsBold, fontWeight: 700 }],
});

ReactGA.initialize("UA-215638009-1");

const loadGMaps = (callback) => {
  const existingScript = document.getElementById("googleMaps");
  if (!existingScript) {
    const script = document.createElement("script");
    script.src =
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyCims7Sdhido85LYt0Lq_t793nSsFrhoIY&libraries=places";
    script.id = "googleMaps";
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  }
  if (existingScript && callback) callback();
};

const updateGA = () => {
  console.log(
    "sending page view:",
    window.location.pathname + window.location.search
  );
  ReactGA.pageview(window.location.pathname + window.location.search);
};

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, comp: Component, ...rest }) {
  //let auth = useAuth();
  let auth = usersSelectors.get();
  console.log("user: " + JSON.stringify(auth));
  return (
    <Route
      {...rest}
      render={(props) =>
        auth.User.id ? ( //FIXME: set to: auth.User.id
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

function App() {
  useEffect(() => {
    updateGA();
    // update app in background
    // TODO: Add some memory variable to update defs only once in a session
    getDefinitions(); // stores definitions into redux
    loadGMaps();
  }, []);
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Suspense fallback={<div></div>}>
            <Switch>
              <Route
                exact
                path="/"
                component={lazy(() => import("./components/pages/Main.js"))}
              />
              <Route
                exact
                path="/login"
                component={lazy(() => import("./components/pages/Login.js"))}
              />
              <Route
                exact
                path="/register"
                component={lazy(() => import("./components/pages/Register.js"))}
              />
              <Route
                exact
                path="/privacy-policy"
                component={lazy(() => import("./components/pages/Privacy.js"))}
              />
              <Route
                exact
                path="/terms-and-conditions"
                component={lazy(() => import("./components/pages/Terms.js"))}
              />
              <Route
                exact
                path="/agenda/:token?"
                component={lazy(() =>
                  import("./components/pages/stream/Public.js")
                )}
              />
              <Route
                exact
                path="/incident/:token?"
                component={lazy(() =>
                  import("./components/pages/incident/Public.js")
                )}
              />
              <Route
                exact
                path="/app/banking/mobile"
                component={lazy(() =>
                  import(
                    "./components/pages/dashboard/accounting/MobileBanking.js"
                  )
                )}
              />
              <Route
                exact
                path="/app/payment/mobile"
                component={lazy(() =>
                  import(
                    "./components/pages/dashboard/accounting/MobilePayment.js"
                  )
                )}
              />
              <PrivateRoute
                exact
                path="/app"
                comp={lazy(() =>
                  import("./components/pages/dashboard/Dashboard.js")
                )}
              />
              <PrivateRoute
                path="/app/association/:id?"
                comp={lazy(() =>
                  import("./components/pages/dashboard/post/Association.js")
                )}
              />
              <PrivateRoute
                path="/app/reporttypes/:id?"
                comp={lazy(() =>
                  import(
                    "./components/pages/dashboard/post/definitions/Reporttypes.js"
                  )
                )}
              />
              <PrivateRoute
                path="/app/documenttypes/:id?"
                comp={lazy(() =>
                  import(
                    "./components/pages/dashboard/post/definitions/Documenttypes.js"
                  )
                )}
              />
              <PrivateRoute
                path="/app/journal/:id?"
                comp={lazy(() =>
                  import(
                    "./components/pages/dashboard/post/definitions/Journal.js"
                  )
                )}
              />
              <PrivateRoute
                path="/app/payment-condition/:id?"
                comp={lazy(() =>
                  import(
                    "./components/pages/dashboard/post/definitions/PaymentCondition.js"
                  )
                )}
              />
              <PrivateRoute
                path="/app/general-ledger-definition/:id?"
                comp={lazy(() =>
                  import(
                    "./components/pages/dashboard/post/definitions/GeneralLedgerDefinition.js"
                  )
                )}
              />
              <PrivateRoute
                path="/app/financial-year/:id?"
                comp={lazy(() =>
                  import(
                    "./components/pages/dashboard/accounting/FinancialYear.js"
                  )
                )}
              />
              <PrivateRoute
                path="/app/settlement/financial-year/:id?"
                comp={lazy(() =>
                  import(
                    "./components/pages/dashboard/accounting/Settlement/Settlement.js"
                  )
                )}
              />
              <PrivateRoute
                path="/app/fixedmeetingitem/:id?"
                comp={lazy(() =>
                  import(
                    "./components/pages/dashboard/post/definitions/FixedMeetingItem.js"
                  )
                )}
              />
              <PrivateRoute
                path="/app/incident/:id?"
                comp={lazy(() =>
                  import("./components/pages/dashboard/post/Incident.js")
                )}
              />
              <PrivateRoute
                path="/app/document/:id?"
                comp={lazy(() =>
                  import("./components/pages/dashboard/post/Document.js")
                )}
              />
              <PrivateRoute
                path="/app/agenda/:id?"
                comp={lazy(() =>
                  import("./components/pages/dashboard/post/Meeting.js")
                )}
              />
              <PrivateRoute
                path="/app/reminders/default/:id?"
                comp={lazy(() =>
                  import("./components/pages/dashboard/post/Reminder.js")
                )}
              />
              <PrivateRoute
                path="/app/reminders/:id?"
                comp={lazy(() =>
                  import("./components/pages/dashboard/post/Reminder.js")
                )}
              />
              <PrivateRoute
                path="/app/supplier/:id?"
                comp={lazy(() =>
                  import("./components/pages/dashboard/post/Supplier.js")
                )}
              />
              <PrivateRoute
                path="/app/lot/:id?"
                comp={lazy(() =>
                  import("./components/pages/dashboard/post/Lot.js")
                )}
              />
              <PrivateRoute
                path="/app/distribution-key/:id?"
                comp={lazy(() =>
                  import("./components/pages/dashboard/post/DistributionKey.js")
                )}
              />
              <PrivateRoute
                path="/app/user/:id?"
                comp={lazy(() =>
                  import("./components/pages/dashboard/post/User.js")
                )}
              />
              <PrivateRoute
                path="/app/stream/agenda/:id"
                comp={lazy(() =>
                  import("./components/pages/dashboard/stream/Meeting.js")
                )}
              />
              <PrivateRoute
                path="/app/invoice/:id?"
                comp={lazy(() =>
                  import("./components/pages/dashboard/accounting/Purchase.js")
                )}
              />
              <PrivateRoute
                path="/app/provision/:id?"
                comp={lazy(() =>
                  import("./components/pages/dashboard/accounting/Provision.js")
                )}
              />
              <PrivateRoute
                path="/app/general-ledger-accounts"
                comp={lazy(() =>
                  import(
                    "./components/pages/dashboard/accounting/GeneralLedgerAccounts.js"
                  )
                )}
              />
              <PrivateRoute
                path="/app/banking"
                comp={lazy(() =>
                  import("./components/pages/dashboard/accounting/Banking.js")
                )}
              />
              <PrivateRoute
                path="/app/payment"
                comp={lazy(() =>
                  import("./components/pages/dashboard/accounting/Payment.js")
                )}
              />
              <Route
                path="*"
                component={lazy(() => import("./components/pages/Error.js"))}
              />
            </Switch>
          </Suspense>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
