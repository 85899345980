import colors from "@syntbeheer/assets/colors";
import React from "react";
import { getAccountingOverview } from "@syntbeheer/api/services/accounting/index";
import CheckBox from "./../../atoms/Input/CheckBox";
import { postPayment } from "@syntbeheer/api/services/accounting/index";
import DropDown from "./../../atoms/Input/DropDown";
import { useTranslation, Trans } from "react-i18next";
import { ExportTable } from "../../organisms/ExportTable";
import DateTimeField from "../../atoms/Input/DateTime";
import { PaymentStatus } from "../../organisms/banking/PaymentStatus";
import * as usersSelectors from "@syntbeheer/api/services/users/selectors";
import DoneIcon from "@material-ui/icons/Done";

export default function Accounting() {
  const [provisions, setProvisions] = React.useState([]);
  const [financialYears, setFinancialYears] = React.useState([]);
  const [showAccounting, setShowAccounting] = React.useState(false);
  const [selectedFinancialYearId, setSelectedFinancialYearId] =
    React.useState(false);
  const { t } = useTranslation("common");

  React.useEffect(() => {
    getAccountingOverview()
      .then((response) => {
        console.log(response);
        setProvisions(response.Provisions);
        setFinancialYears(
          response.FinancialYears.sort((a, b) => (a.year > b.year ? -1 : 1))
        );
        if (response.GLAccounts?.length) {
          setShowAccounting(true);
        }
      })
      .catch((e) => console.log(e));
  }, []);

  return (
    <div className="row">
      <div className="col">
        <div className="row">
          <div className="col">
            <h2 style={{ fontWeight: 700, color: colors.primary }}>
              {t("dashboard.accounting.title", "Accountancy")}
            </h2>
          </div>

          <div className="col my-auto d-flex justify-content-end">
            <DropDown
              label=""
              options={[
                {
                  id: false,
                  year: t(
                    "dashboard.accounting.fiscalYearsDropdown.allYearsOption",
                    "All fiscal years"
                  ),
                },
                ...financialYears,
              ].map((Y) => {
                Y.label = `${Y.year}`;
                return Y;
              })}
              field="id"
              value={selectedFinancialYearId}
              placeholder={t(
                "dashboard.accounting.fiscalYearsDropdown.placeholder",
                "Filter by fiscal year"
              )}
              onChange={(selected) => {
                setSelectedFinancialYearId(selected.id);
              }}
              marginbottom={0}
              style={{ minWidth: 300 }}
            />
          </div>
        </div>

        {showAccounting ? (
          <>
            <ProvisionsTable
              data={[
                ...provisions.filter(
                  (P) =>
                    !P.paid_at &&
                    (selectedFinancialYearId === false ||
                      selectedFinancialYearId === P.FinancialYearId)
                ),
                ...provisions
                  .filter(
                    (P) =>
                      P.paid_at &&
                      (selectedFinancialYearId === false ||
                        selectedFinancialYearId === P.FinancialYearId)
                  )
                  .sort((a, b) => (a.invoice_date > b.invoice_date ? -1 : 1)),
              ]}
              onChangeProvisionStatus={(id, checked) => {
                const P = provisions.find((p) => p.id === id);
                if (P) {
                  postPayment({
                    id: P.id,
                    paid_at: P.paid_at,
                    type: "Provision",
                  }).then((res) => console.log(res));
                }

                setProvisions((provisions) =>
                  provisions.map((P) => {
                    if (P.id === id) {
                      return {
                        ...P,
                        paid_at: checked,
                        status: checked
                          ? t(
                              "dashboard.accounting.provisions.columns.status.paid",
                              "Paid"
                            )
                          : t(
                              "dashboard.accounting.provisions.columns.status.notPaid",
                              "Opened"
                            ),
                      };
                    }
                    return P;
                  })
                );
              }}
            />
          </>
        ) : (
          <div>
            <Trans
              i18nKey="dashboard.accounting.hideAccounting.description"
              t={t}
              ns="common"
            >
              First of all, the general ledger accounts of the VME must be
              known. Go to{" "}
              <a href="/app/general-ledger-accounts">G/L Accounts</a>.
            </Trans>
          </div>
        )}
      </div>
    </div>
  );
}

function ProvisionsTable({ data, onChangeProvisionStatus }) {
  const User = usersSelectors.get().User;
  const hasAccessToCreate =
    User?.UserVME?.type !== "synt_viewer" &&
    User?.UserVME?.type !== "commissioner";

  const { t } = useTranslation("common");

  const columnsProvisions = [
    {
      Header: "Id",
      accessor: "id",
      showOnEmail: true,
    },
    {
      Header: t(
        "dashboard.accounting.provisions.columns.reference",
        "Reference"
      ),
      accessor: "reference",
      Cell: ({ row, value }) => {
        return hasAccessToCreate ? (
          <a href={`/app/provision/${row.values.id}`}>{value}</a>
        ) : (
          value
        );
      },
      showOnEmail: true,
    },
    {
      Header: t("dashboard.accounting.provisions.columns.period", "Period"),
      accessor: "period",
      showOnEmail: true,
    },
    {
      Header: t("dashboard.accounting.provisions.columns.invoice", "Invoiced"),
      accessor: (row) =>
        row.Lot.name +
        " (" +
        (row.Users[0]?.CompanyId
          ? row.Users[0].Company.name
          : row.Users[0].full_name) +
        ")",
      showOnEmail: true,
    },
    {
      Header: t("dashboard.accounting.provisions.columns.amount", "Amount"),
      accessor: (row) => ({
        amount: row.amount
          ? `${row.amount
              .toFixed(2) // always two decimal digits
              .replace(".", ",") // replace decimal point character with ,
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}`
          : "" +
            (row.correction > 0
              ? `${row.correction
                  .toFixed(2)
                  .replace(".", ",")
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")} (C)`
              : ""),
        row: row,
      }),
      Cell: ({ row, value }) => value.amount,
      showOnEmail: true,
      aggregate: (leaf, group) => {
        console.log(group);
        const total = group.reduce((sum, item) => {
          const normalizedAmount = item.amount
            .replace(/\./g, "")
            .replace(",", ".");
          const parsedAmount = parseFloat(normalizedAmount);
          return sum + parsedAmount;
        }, 0);
        console.log({ total });
        const paid = group.reduce(
          (sum, item) =>
            sum +
            (item.row.paid_at
              ? parseFloat(item.amount.replace(/\./g, "").replace(",", "."))
              : 0),
          0
        );
        return {
          amount:
            total !== paid ? (
              total
                .toFixed(2) // always two decimal digits
                .replace(".", ",") // replace decimal point character with ,
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") +
              " (" +
              paid
                .toFixed(2) // always two decimal digits
                .replace(".", ",") // replace decimal point character with ,
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") +
              " " +
              t("Betaald", "Betaald") +
              ")"
            ) : (
              <>
                {total
                  .toFixed(2) // always two decimal digits
                  .replace(".", ",") // replace decimal point character with ,
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                <DoneIcon
                  style={{ color: colors.success, stroke: colors.success }}
                />
              </>
            ),
          status: null,
        };
      },
    },
    {
      Header: t(
        "dashboard.accounting.provisions.columns.status.header",
        "Status"
      ),
      accessor: "status",
      Cell: ({ row, value }) => {
        return (
          <div className="d-flex justify-content-between align-items-center">
            {!!row.original ? (
              <>
                {row.original && (
                  <PaymentStatus
                    id={row.original.id}
                    type="Provision"
                    paid_at={row.original.paid_at}
                    payment={row.original.payment}
                    hasAccess={hasAccessToCreate}
                  />
                )}
                {hasAccessToCreate && value && (
                  <div>
                    <CheckBox
                      label=""
                      checked={
                        value ===
                        t(
                          "dashboard.accounting.provisions.columns.status.paid",
                          "Paid"
                        )
                      }
                      onChange={(event) => {
                        onChangeProvisionStatus(
                          row.values.id,
                          event.target.checked
                        );
                      }}
                      marginbottom={0}
                    />
                  </div>
                )}
              </>
            ) : (
              <span>{value}</span>
            )}
          </div>
        );
      },
      showOnEmail: false,
    },
  ];

  return (
    <ExportTable
      columns={columnsProvisions}
      data={data}
      title={t("dashboard.accounting.provisions.title", "Queries")}
      enableSearch={true}
      enableReminder={true}
      enableSort={true}
      groupedByAccessors={["period", "status"]}
      addPath={hasAccessToCreate ? "/app/provision" : null}
      addTitle={t("dashboard.accounting.provisions.addButton", "New query")}
      exportFileName="provisions"
      renderDataFilterComponent={({ filters, setFilters }) => (
        <DataFilterComponent filters={filters} setFilters={setFilters} />
      )}
      dataFilter={dataFilter}
    />
  );
}

function DataFilterComponent({ filters, setFilters }) {
  const { t } = useTranslation("common");

  return (
    <div className="row mt-3 ml-3">
      <div className="col-md-4">
        <DateTimeField
          label={t(
            "dashboard.financialYearForm.fields.startDate.label",
            "Start date"
          )}
          name="start_date"
          value={filters.start_date ?? false}
          onChange={(start_date) =>
            setFilters((prev) => ({ ...prev, start_date }))
          }
          max={filters.end_date}
          utc={true}
          futureonly={0}
        />
      </div>
      <div className="col-md-4">
        <DateTimeField
          label={t(
            "dashboard.financialYearForm.fields.endDate.label",
            "End date"
          )}
          name="end_date"
          value={filters.end_date}
          onChange={(end_date) => setFilters((prev) => ({ ...prev, end_date }))}
          min={filters?.start_date ?? ""}
          utc={true}
          futureonly={0}
        />
      </div>
    </div>
  );
}

function dataFilter(data, filters) {
  let filteredData = data;

  if (filters.start_date && filters.start_date.getTime() > 0) {
    filteredData = filteredData.filter((item) => {
      return new Date(item.invoice_date) >= filters.start_date;
    });
  }

  if (filters.end_date && filters.end_date.getTime() > 0) {
    filteredData = filteredData.filter((item) => {
      return new Date(item.invoice_date) <= filters.end_date;
    });
  }

  return filteredData;
}
