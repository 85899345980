import React from "react";
//import Datetime from "react-datetime";
//import "react-datetime/css/react-datetime.css";
//import moment from "moment";
//import "moment/locale/nl";
import InputField from "./Field";
import { withTranslation } from "react-i18next";

function dateToAmerican(date) {
  if (date) {
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var year = date.getFullYear();

    if (month < 10) month = "0" + month.toString();
    if (day < 10) day = "0" + day.toString();
    while (year.toString().length !== 4) {
      year = "0" + year.toString();
    }

    return year + "-" + month + "-" + day;
  } else {
    return null;
  }
}

function timeToAmerican(date) {
  if (date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();

    if (hours < 10) hours = "0" + hours.toString();
    if (minutes < 10) minutes = "0" + minutes.toString();

    return hours + ":" + minutes;
  } else {
    return null;
  }
}

class DateTimeField extends React.Component {
  static defaultProps = {
    futureonly: true,
    initial: true,
    dateformat: "DD-MM-YYYY",
    showtime: "",
  };
  render() {
    const { t } = this.props;
    const { props } = this;
    return (
      <div className="form-group has-validation">
        <div className="row">
          {props.dateformat && (
            <div className={props.showtime === "yes" ? "col-md-6" : "col"}>
              <InputField
                type="date"
                name={this.props.name}
                label={this.props.label}
                value={
                  this.props.value === null || this.props.value === false
                    ? ""
                    : dateToAmerican(new Date(this.props.value))
                }
                min={
                  this.props.min
                    ? dateToAmerican(new Date(this.props.min))
                    : props.futureonly
                    ? dateToAmerican(new Date())
                    : ""
                }
                max={dateToAmerican(new Date(this.props.max))}
                onChange={(v) => {
                  let date = v;
                  let value = props.value
                    ? new Date(props.value)
                    : new Date(new Date().setHours(20, 0, 0, 0));
                  value.setMonth(date.split("-")[1] - 1);
                  value.setDate(date.split("-")[2]);
                  value.setFullYear(date.split("-")[0]);
                  console.log(new Date(value));
                  props.onChange(new Date(value));
                }}
                onClick={this.props.onClick}
                error={this.props.error}
                marginbottom={props.help ? 0 : 1}
                disabled={props.disabled}
              />
            </div>
          )}
          {props.showtime === "yes" && (
            <div className={props.dateformat ? "col-md-6" : "col"}>
              <InputField
                {...props}
                type="time"
                label={
                  props.label + " " + t("dashboard.dateTime.hours", "(hours)")
                }
                value={
                  props.value === null || props.value === false
                    ? ""
                    : timeToAmerican(new Date(props.value))
                }
                min={timeToAmerican(this.props.min)}
                max={timeToAmerican(this.props.max)}
                onChange={(v) => {
                  let time = v;
                  let value = props.value
                    ? new Date(props.value)
                    : new Date(new Date().setHours(20, 0, 0, 0));
                  value.setHours(time.split(":")[0]);
                  value.setMinutes(time.split(":")[1]);
                  props.onChange(new Date(value));
                }}
                marginbottom={props.help ? 0 : 1}
              />
            </div>
          )}
        </div>

        {/* 
        <Datetime
          {...props}
          value={initial}
          isValidDate={valid}
          input={true}
          aria-describedby={`${props.name}Help`}
          className={`${props.error ? "is-invalid" : ""}`}
          style={props.error ? { borderColor: "#f44336" } : {}}
          inputProps={{
            disabled: props.disabled || false,
            placeholder: props.placeholder || false,
          }}
        />
         */}
        {props.help && (
          <small
            id={`${props.name}Help`}
            className="form-text text-muted mb-5"
            style={{ marginTop: "-1rem" }}
          >
            {props.help}
          </small>
        )}
        {props.error && (
          <small
            id={`${props.name}Help`}
            className="invalid-feedback form-text mb-5"
            style={{ marginTop: "-1rem" }}
          >
            {props.error}
          </small>
        )}
      </div>
    );
  }
}

export function MonthField(props) {
  return (
    <div className="form-group has-validation">
      <div className="row">
        <div className="col">
          <InputField
            type="month"
            name={props.name}
            label={props.label}
            value={props.value ? props.value : ""}
            min={props.min}
            max={props.max}
            onChange={props.onChange}
            onClick={props.onClick}
            error={props.error}
            marginbottom={props.help ? 0 : 1}
            disabled={props.disabled}
          />
        </div>
      </div>

      {props.help && (
        <small
          id={`${props.name}Help`}
          className="form-text text-muted mb-5"
          style={{ marginTop: "-1rem" }}
        >
          {props.help}
        </small>
      )}
      {props.error && (
        <small
          id={`${props.name}Help`}
          className="invalid-feedback form-text mb-5"
          style={{ marginTop: "-1rem" }}
        >
          {props.error}
        </small>
      )}
    </div>
  );
}

export default withTranslation("common")(DateTimeField);
